// src/pages/Blog.tsx
import React from 'react';
import { ArticleBlog } from '../components/ArticleBlog';
import { Footer } from '../components/Footer';
const PageBlog: React.FC = () => {
    return (
      <>
        <ArticleBlog />
        <Footer backgroundColor="bgLight colorLight" />
      </>
    );
  };
  
  export default PageBlog;