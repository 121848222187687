import React from 'react';
import './SkillItem.css';

interface Interval {
  start: number;
  end: number;
  color: string;
}

interface SkillItemProps {
  imgSrc: string;
  label: string;
  intervalsColor?: Interval[];
}

const SkillItem: React.FC<SkillItemProps> = ({ imgSrc, label, intervalsColor }) => {
  const applyColorsToLabel = (label: string, intervalsColor: Interval[]): JSX.Element => {
    let formattedLabel = label;

    intervalsColor.forEach(({ start, end, color }) => {
      formattedLabel = formattedLabel.slice(0, start) + `<span style="color: ${color}">` + formattedLabel.slice(start, end) + '</span>' + formattedLabel.slice(end);
    });

    return <span dangerouslySetInnerHTML={{ __html: formattedLabel }} />;
  };

  return (
    <div className="col">
      <div>
        <img className="py-5" src={imgSrc} alt={label} />
      </div>
      <h4 className="pb-4">{intervalsColor ? applyColorsToLabel(label, intervalsColor) : label}</h4>
    </div>
  );
};

export default SkillItem;
