// src/components/PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <section id="PrivacyPolicy" className="bgLight colorLight">
      <div className="container py-5">
            <h1 className="text-center title-decoration section-title py-4">Politique de Confidentialité</h1>
            <div className="row">
                <div className="col-10 m-auto">
                    <section className="my-4">
                    <h2>Introduction</h2>
                    <p>
                        Nous attachons une grande importance à la confidentialité de vos informations personnelles.
                        Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons et les protégeons.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Informations Collectées</h2>
                    <p>
                        Nous collectons différents types d'informations, y compris :
                    </p>
                    <ul>
                        <li>Les informations que vous nous fournissez directement (nom, adresse e-mail, etc.).</li>
                        <li>Les informations collectées automatiquement (adresse IP, type de navigateur, etc.).</li>
                        <li>Les informations collectées via les cookies et technologies similaires.</li>
                    </ul>
                    </section>

                    <section className="my-4">
                    <h2>Utilisation des Informations</h2>
                    <p>
                        Nous utilisons les informations collectées pour :
                    </p>
                    <ul>
                        <li>Fournir et améliorer nos services.</li>
                        <li>Personnaliser votre expérience utilisateur.</li>
                        <li>Vous envoyer des communications marketing et promotionnelles.</li>
                        <li>Analyser l'utilisation de notre site web et en améliorer les fonctionnalités.</li>
                    </ul>
                    </section>

                    <section className="my-4">
                    <h2>Partage des Informations</h2>
                    <p>
                        Nous ne partageons vos informations personnelles qu'avec :
                    </p>
                    <ul>
                        <li>Les prestataires de services tiers qui travaillent en notre nom.</li>
                        <li>Les autorités légales si cela est requis par la loi.</li>
                    </ul>
                    </section>

                    <section className="my-4">
                    <h2>Protection des Informations</h2>
                    <p>
                        Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, modification, divulgation ou destruction.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Vos Droits</h2>
                    <p>
                        Vous avez le droit de :
                    </p>
                    <ul>
                        <li>Accéder à vos informations personnelles.</li>
                        <li>Demander la correction de vos informations personnelles.</li>
                        <li>Demander la suppression de vos informations personnelles.</li>
                        <li>Vous opposer au traitement de vos informations personnelles.</li>
                    </ul>
                    </section>

                    <section className="my-4">
                    <h2>Modifications de cette Politique</h2>
                    <p>
                        Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique sur notre site web.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Contactez-nous</h2>
                    <p>
                        Si vous avez des questions concernant cette politique de confidentialité, 
                        vous pouvez nous contacter à : <a href="mailto: goodshiteam@gmail.com">goodshiteam@gmail.com</a>
                    </p>
                    </section>
                </div>
            </div>
        </div>
    </section>
  );
};

export default PrivacyPolicy;
