import React from 'react';
import BlogItem from './BlogItem';
import { Link } from 'react-router-dom';

const Blog: React.FC = () => {
    const BlogItemData = [
        {
          title: "Introduction à React",
          texte: "React est une bibliothèque JavaScript développée par Facebook pour créer des interfaces utilisateur interactives et dynamiques. Il permet de construire des applications web en utilisant des composants réutilisables. Dans cet article, nous allons explorer les bases de React pour vous aider à démarrer.",
          image: "/images/illustration-react-app.jpg",
          tags: ["react","débutant"],
          date: "6 juin 2024",
          id:"introduction-react"
        },
        {
          title: "L'importance des sites internet en 2024",
          texte: "Dans le monde numérique d'aujourd'hui, les sites internet jouent un rôle crucial pour les entreprises, les particuliers, et les organisations. En 2024, leur importance ne fait que croître avec les avancées technologiques et les attentes croissantes des utilisateurs. Cet article explore pourquoi les sites internet sont indispensables en 2024 et comment ils peuvent bénéficier à différentes entités.",
          image: "/images/illustration-site-web.jpg",
          tags: ["site internet", "2024"],
          date: "5 juin 2024",
          id:"importance-sites-internet-2024"
        },
        {
          title: "Introduction aux Langages de Programmation",
          texte: "Dans le monde du développement informatique, les langages de programmation jouent un rôle fondamental. En 2024, comprendre les bases des langages de programmation est essentiel pour quiconque souhaite se lancer dans le développement ou améliorer ses compétences. Cet article explore les principaux langages de programmation, leurs utilisations et pourquoi ils sont indispensables.",
          image: "/images/illustration-programming.jpg",
          tags: ["langages de programmation", "debutant"],
          date: "4 juin 2024",
          id: "introduction-langages-programmation-2024"
        }
    ];

    const firstBlogItem = BlogItemData[0];
    const remainingBlogItems = BlogItemData.slice(1);

    let rowCount = 0; // Compteur de lignes rendues
    let separationClass = '';
    return (
      <section id="Blog" className="bgLight">
        <div className="container-fluid py-5">
        <h1 className="text-center title-decoration section-title py-4 mb-5">Notre Blog</h1>
          <div className="row bgLight colorLight rounded my-5 py-5 text-center">
            <div className="col-md-1"></div>
            <div className="col-md-5 d-flex align-items-center">
              <div>
                <h2 className="mb-4 main-title-blogItem pb-4">
                  <span className="pb-4 pe-5">{firstBlogItem.title}</span>
                </h2>
                <p className="m-0">{firstBlogItem.texte}</p>
                <Link to={`/articleBlog/${firstBlogItem.id}`}>
                  <div className="learn-more-button text-center p-3 my-5 mb-md-0 mt-5">LIRE L'ARTICLE</div>
                </Link>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <img src={firstBlogItem.image} alt="Blog illustration" className="img-fluid rounded" />
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row my-2 g-4">
            <div className="d-none d-lg-block w-100 row-separation"></div>
            {remainingBlogItems.map((item, index) => {
              const isEvenElement = index % 2 === 0;
              const position = isEvenElement ? 'even' : 'odd';
              const isNewRow = index % 2 === 0;

              // Incrémenter le compteur de lignes
              if (isNewRow) {
                rowCount++;
              }
              if(rowCount % 2 === 0){
                separationClass = isNewRow ? `col-2 col-separation bgDark col-separation-even` : '';
              }else{
                separationClass = isNewRow ? `col-2 col-separation bgDark col-separation-odd` : '';
              }
              return (
                <React.Fragment key={index}>
                  {isNewRow && <div className="d-none d-lg-block col-1 bgDark"></div>}
                  {isNewRow && index !== 0 && <div className="w-100 row-separation"></div>}
                  {isNewRow && index !== 0 && <div className="d-none d-lg-block col-1 bgDark"></div>}
                  <div className="col-md-5 col-lg-4 text-center bgDark colorDark">
                    <BlogItem
                      title={item.title}
                      texte={item.texte}
                      image={item.image}
                      tags={item.tags}
                      date={item.date}
                      link={`/articleBlog/${item.id}`}
                      position={position}
                    />
                  </div>
                  {isEvenElement && <div className={separationClass}></div>}
                </React.Fragment>
              );
            })}
            <div className="d-none d-lg-block col-1 bgDark"></div>
            <div className="w-100 row-separation"></div>
          </div>
        </div>
      </section>
    );
};

export default Blog;
