// src/components/Header.tsx
import React from 'react';
import './About.css';

const About: React.FC = () => {
  return (
    <section id="About" className="bgDark colorDark py-5 watch-color">
      <div className="container-fluid d-flex align-items-center">
        <div className="about-text col-md-6 p-5">
          <h3>Good<span className="text-hightlight">Shi</span></h3>
          <h1 className="py-5">QUI SOMMES-NOUS ?</h1>
          <p className="lead mb-5">
            Nous sommes une équipe d'experts en développement informatique, réunissant deux professionnels chevronnés. Notre mission est de créer des applications sur mesure qui répondent parfaitement aux besoins de nos clients. Grâce à notre expertise technique, notre sens du design et notre approche collaborative, nous sommes prêts à relever tous les défis pour concrétiser vos projets numériques.
            <br/><br/>
            Votre problème ? <span className="text-hightlight">Notre solution.</span>
          </p>
          <a href="#Services" className="btn btnDark">Découvrir</a>
        </div>
        <div className="col-md-6 text-center">
          <img src="/images/map.png" alt="Background" className="about-background pt-5 position-mb-absolute" />
        </div>
      </div>
      </section>
  );
};

export default About;
