// src/components/FooterLinkSection.tsx
import React from 'react';
import { Link } from 'react-router-dom';

interface Link {
  href: string;
  label: string;
  external?: boolean;
}

interface FooterLinkSectionProps {
  title: string;
  links: Link[];
}

const FooterLinkSection: React.FC<FooterLinkSectionProps> = ({ title, links }) => {
  return (
    <div className="col-6">
      <h5 className="mb-4">{title}</h5>
      {links.map((link, index) => (
        <p key={index}>
          {link.external ? (
            <a href={link.href} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
              {link.label}
            </a>
          ) : (
            <Link to={link.href} className="text-decoration-none">
              {link.label}
            </Link>
          )}
        </p>
      ))}
    </div>
  );
};

export default FooterLinkSection;
