import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import './FAQItem.css';

interface FAQItemProps {
  question: string;
  response: React.ReactNode;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, response }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="col-10 m-auto FAQitem">
      <div className="py-4 question position-relative" onClick={toggleVisibility}>
        <div className='orange-square d-none d-md-block'></div>       
        <div className={`orange-square ${isVisible ? 'under' : 'in'} d-none d-md-block`}></div>
        <h5 className="text-center mb-0">        
          {question} <span className="ms-2">{isVisible ? <FontAwesomeIcon icon={faMinus}/> : <FontAwesomeIcon icon={faPlus}/>} </span>
        </h5>
        <CSSTransition
          in={isVisible}
          timeout={{ enter: 1000, exit: 0 }}
          classNames="response"
          unmountOnExit
        >
          <div className="ps-4 mx-auto mt-3 response">{response}</div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default FAQItem;
