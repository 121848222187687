// src/components/TermsOfUse.tsx
import React from 'react';

const TermsOfUse: React.FC = () => {
  return (
    <section id="TermsOfUse" className="bgLight colorLight">
        <div className="container py-5">
            <h1 className="text-center title-decoration section-title py-4">Conditions Générales d'Utilisation</h1>
            <div className="row">
                <div className="col-10 m-auto">
                    <section className="my-4">
                    <h2>Introduction</h2>
                    <p>
                        Bienvenue sur notre site web. En accédant à ce site, vous acceptez d'être lié par les présentes conditions générales d'utilisation (CGU), toutes les lois et règlements applicables, et acceptez que vous êtes responsable du respect de toutes les lois locales applicables.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Acceptation des CGU</h2>
                    <p>
                        En accédant et en utilisant ce site, vous acceptez les présentes conditions d'utilisation sans réserve ni restriction.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Modification des CGU</h2>
                    <p>
                        Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications seront effectives dès leur publication sur le site.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Accès au site</h2>
                    <p>
                        Nous nous efforçons de maintenir le site accessible en permanence, mais nous ne pouvons garantir son accès ininterrompu. Nous nous réservons également le droit de suspendre l'accès au site pour des raisons techniques ou de maintenance.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Utilisation du site</h2>
                    <p>
                        Vous vous engagez à utiliser ce site de manière conforme aux lois et règlements en vigueur. Il est interdit d'utiliser ce site à des fins illégales, nuisibles, ou offensantes.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Contenu du Site</h2>
                    <p>
                        Tous les contenus présents sur ce site, incluant textes, graphiques, logos, et images, sont la propriété de notre entreprise et sont protégés par les lois de propriété intellectuelle.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Liens Externes</h2>
                    <p>
                        Notre site peut contenir des liens vers des sites externes. Nous ne sommes pas responsables du contenu de ces sites externes ni de leur politique de confidentialité.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Responsabilité</h2>
                    <p>
                        Nous ne pouvons être tenus responsables des dommages directs ou indirects résultant de l'utilisation de ce site ou de l'impossibilité d'y accéder.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Protection des Données</h2>
                    <p>
                        Vos données personnelles sont protégées conformément à notre politique de confidentialité, que vous pouvez consulter sur notre site.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Loi Applicable et Juridiction</h2>
                    <p>
                        Ces conditions sont régies par la loi française. Tout litige relatif à leur interprétation ou leur exécution sera de la compétence exclusive des tribunaux français.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Contact</h2>
                    <p>
                        Pour toute question relative à ces conditions générales d'utilisation, 
                        vous pouvez nous contacter à l'adresse suivante : <a href="mailto:goodshiteam@gmail.com">goodshiteam@gmail.com</a>   
                    </p>
                    </section>
                </div>
            </div>
        </div>
    </section>
  );
};

export default TermsOfUse;
