import React from 'react';
import SkillItem from './SkillItem';

const skillsData = [
  { imgSrc: '/images/innovation.png', label: 'Innovation', intervalsColor: [{ start: 0, end: 4, color: '#fff' }] },
  { imgSrc: '/images/collaboration.png', label: 'Coopération', intervalsColor: [{ start: 0, end: 4, color: '#F39829' }] },
  { imgSrc: '/images/adaptabilite.png', label: 'Réactivité', intervalsColor: [{ start: 0, end: 4, color: '#AADAB1' }] },
  { imgSrc: '/images/accompagner.png', label: 'Assistance', intervalsColor: [{ start: 0, end: 6, color: '#E1E0AA' }] },
  { imgSrc: '/images/communication.png', label: 'Communication', intervalsColor: [{ start: 0, end: 5, color: '#9A3043' }] }
];

export const Skills: React.FC = () => {
  return (
    <section id="Skills" className="bgDark colorDark text-center">
      <div className="container">
        <div className="row row-cols-1  row-cols-md-2 row-cols-lg-5">
          {skillsData.map((skill, index) => (
            <SkillItem key={index} imgSrc={skill.imgSrc} label={skill.label} intervalsColor={skill.intervalsColor} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
