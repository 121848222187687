// src/pages/Home.tsx
import React from 'react';
import { About } from '../components/About';
import { Services } from '../components/Services';
import { Skills } from '../components/Skills';
import { Features } from '../components/Features';
import { Testimonials } from '../components/Testimonials';
import { Footer } from '../components/Footer';
const Home: React.FC = () => {
  return (
    <>
      <About />
      <Services />
      <Skills />
      <Features />
      <Footer backgroundColor="bgDark colorDark" />
    </>
  );
};

export default Home;
