import React, { useState } from 'react';
import ServiceCard from './ServiceCard';

const Services: React.FC = () => {
  const [showAll, setShowAll] = useState(false);
  
  const servicesData = [
    {
      title: "Création de Sites Web",
      list: [
        "Design, conception et développement",
        "Expérience utilisateur optimisée",
        "SEO pour une meilleure visibilité"
      ],
      numero: "01",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png"
    },
    {
      title: "Développement d'Applications",
      list: [
        "Mobile et web sur mesure",
        "Fonctionnalités avancées",
        "Intégration d'API"
      ],
      numero: "02",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png"
    },
    {
      title: "Formations Personnalisées",
      list: [
        "Adaptées à vos besoins",
        "Performances, sécurité, référencement",
        "Intervention en entreprise"
      ],
      numero: "03",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png"
    },
    {
      title: "Analyse de Données",
      list: [
        "Collecte et analyse de données",
        "Rapports pour mesurer l'efficacité de vos efforts numériques"
      ],
      numero: "04",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png"
    },
    {
      title: "E-commerce",
      list: [
        "Création de boutiques en ligne",
        "Gestion des paiements et des commandes"
      ],
      numero: "05",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png"
    },
    {
      title: "Audit et Conseils",
      list: [
        "Evaluation de votre présence en ligne",
        "Recommandations et planification stratégique pour l'évolution de votre entreprise"
      ],
      numero: "06",
      image: "/images/light.png",
      hoverImage: "/images/light_on.png"
    }
  ];
  // Filtrer les services pour n'afficher que les 3 premiers ou tous
  const visibleServices = showAll ? servicesData : servicesData.slice(0, 3);
  return (
    <section id="Services" className={`py-5 bgLight colorLight ${showAll ? 'expanded' : ''}`}>
      <div className="container">
        <h2 className="text-center title-decoration section-title py-4">Nos services</h2>
        <p className="text-center mb-5 lead">
          <b>Bienvenue chez Good<span className="text-hightlight">Shi</span> ! Nous sommes là pour rendre votre présence en ligne aussi simple et efficace que possible. Découvrez nos services ci-dessous pour voir comment nous pouvons vous aider à atteindre vos objectifs numériques.</b>
        </p>
        <div className="row row-cols-1 row-cols-lg-3 g-lg-6">
        {visibleServices.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              list={service.list}
              numero={service.numero}
              image={service.image}
              hoverImage={service.hoverImage}
            />
          ))}
        </div>
        {showAll ? (
          <div className="text-center mt-4">
            <button className="btn btnLight" onClick={() => setShowAll(false)}>
              Voir moins
            </button>
          </div>
        ) : (
          <div className="text-center mt-4">
            <button className="btn btnLight" onClick={() => setShowAll(true)}>
              Voir plus
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Services;
