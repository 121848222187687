import React from 'react';
import FeatureItem from './FeatureItem';

const Features: React.FC = () => {
  const FeatureData = [
    /*{
      title: "Twitch",
      description: "Découvrez notre chaîne Twitch dédiée au développement informatique, un espace où la création rencontre la communauté. Rejoignez-nous en direct pour explorer le monde du développement logiciel, poser vos questions et participer à des discussions animées. Que vous soyez débutant ou expert, notre chaîne Twitch est l'endroit idéal pour découvrir, apprendre et échanger sur les dernières tendances et les meilleures pratiques de programmation. Rejoignez-nous pour une expérience interactive et enrichissante !",
      link: "Lorem ipsum dolor sit amet.com",
      image: "/images/twitch.png"
    },*/
    {
      title: "Le blog",
      description: "Explorez notre blog dédié à la programmation, un espace où la découverte rencontre l'apprentissage. Nous nous efforçons de rendre les sujets complexes accessibles à tous, tout en partageant les dernières tendances et en favorisant les échanges d'idées. Que vous soyez débutant cherchant à apprendre ou expert désireux de partager vos connaissances, notre blog est l'endroit idéal pour explorer le vaste monde de la programmation. Rejoignez-nous pour une expérience enrichissante et collaborative !",
      link: "/blog",
      image: "/images/blog.png"
    }

  ];

  return (
    <section id="Features" className="py-5 bgLight colorLight watch-color">
      <div className="container px-md-5">
        <h2 className="text-center title-decoration section-title py-4 mb-5">Nos plus</h2>
        <div className="row g-md-6">
          {FeatureData.map((feature, index) => (
              <FeatureItem
                key={index}
                title={feature.title}
                description={feature.description}
                link={feature.link}
                image={feature.image}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
