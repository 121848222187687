// src/pages/FAQ.tsx
import React from 'react';
import { FAQ } from '../components/FAQ';
import { Footer } from '../components/Footer';
const PageFAQ: React.FC = () => {
    return (
      <>
        <FAQ />
        <Footer backgroundColor="bgDark colorDark" />
      </>
    );
  };
  
  export default PageFAQ;