// src/components/FAQContent.tsx
import React from 'react';
import FAQItem from './FAQItem';
import { Link } from 'react-router-dom';

const FAQ: React.FC = () => {
  const FAQItemData = [
    {
      question: "Quels services proposez-vous ?",
      response: (
        <span>
          Nous offrons des services complets en création de sites web (design UX/UI, développement, SEO), développement d'applications mobiles et web, formations en développement, et optimisation de performances, sécurité et référencement. Nous intervenons également en entreprise pour renforcer vos équipes selon vos besoins.
        </span>
      ),
    },
    {
      question: "Comment fonctionne votre processus de création de site web ou d'application ?",
      response: (
        <span>
        Notre processus de création comprend plusieurs étapes :
        <ul>
          <li><strong>Contact initial</strong> : Nous discutons afin de cerner avec précision vos besoins.</li>
          <li><strong>Propositions</strong> : Nous élaborons et vous présentons plusieurs propositions.</li>
          <li><strong>Développement</strong> : Une fois la proposition validée, nous lançons le développement.</li>
          <li><strong>Communication</strong> : Nous restons en contact régulier avec vous pour nous assurer que tout correspond à vos attentes.</li>
          <li><strong>Livraison</strong> : À la fin du développement, nous livrons le projet complet et pouvons également assurer l'installation si nécessaire.</li>
        </ul>
      </span>
      ),
    },
    {
      question: "Quels types de formations proposez-vous ?",
      response: (
        <span>
          Nous proposons une gamme variée de formations pour répondre aux besoins de nos clients. Nos formations incluent des cours sur le développement web et mobile, l'utilisation d'outils et de frameworks spécifiques, la conception d'interfaces utilisateur, ainsi que des sessions de perfectionnement professionnel. Que vous soyez débutant ou expérimenté, nos formations sont conçues pour vous aider à acquérir de nouvelles compétences et à rester à jour avec les dernières technologies et pratiques de l'industrie.
        </span>
      ),
    },
    {
      question: "Combien de temps faut-il pour développer un site web ou une application ?",
      response: (
        <span>
          Le temps de développement dépend de la complexité du projet. Voici quelques estimations pour des projets classiques :
          <ul>
            <li><strong>Site Web Vitrine :</strong> 2 à 4 semaines</li>
            <li><strong>Site Web Dynamique :</strong> 4 à 8 semaines</li>
            <li><strong>Boutique en Ligne :</strong> 8 à 12 semaines</li>
            <li><strong>Application Mobile Simple :</strong> 8 à 12 semaines</li>
            <li><strong>Application Web Personnalisée :</strong> 12 à 24 semaines</li>
            <li><strong>Application Mobile Complexe :</strong> 12 à 24 semaines</li>
          </ul>
        </span>
      ),
    },
    {
      question: "Quels langages de programmation et technologies utilisez-vous ?",
      response: (
        <span>
          <ul>
            <li><strong>Front :</strong> TypeScript (React)</li>
            <li><strong>Back :</strong> Java (Spring), Php (Symfony)</li>
          </ul>
        </span>
      ),
    },
    {
      question: "Proposez-vous des services de maintenance et de support après la livraison ?",
      response: (
        <span>
          Oui, nous assurons la maintenance et le support de toutes les solutions que nous développons.
        </span>
      ),
    },
    {
      question: "Comment puis-je obtenir un devis pour mon projet ?",
      response: (
        <span>
          Pour obtenir un devis pour votre projet, il vous suffit de prendre <Link to="/contact">contact</Link> avec notre équipe. Nous serons ravis de discuter de vos besoins et de vous fournir une estimation personnalisée pour la réalisation de votre projet.
        </span>
      ),
    },
    {
      question: "Quels sont vos tarifs pour les différents services ?",
      response: (
        <div>
          <p>Les tarifs pour les différents services peuvent varier en fonction de vos besoins. C'est pour quoi nous avons besoin d'un premier échange avec vous afin de vous faire une proposition adaptée</p>
          <p>Voici une estimation générale des tarifs pratiqués pour des cas classiques :</p>
          <ul>
            <li><strong>Site Vitrine :</strong> Les tarifs peuvent varier entre 1000€ et 5000€.</li>
            <li><strong>Boutique en Ligne (E-commerce) :</strong> Les tarifs peuvent se situer entre 2000€ et 10000€. En plus des tarifs de développement de la boutique en ligne, des frais supplémentaires peuvent s'appliquer pour l'intégration de systèmes de paiement, la gestion des commandes, etc.</li>
            <li><strong>Application Mobile :</strong> Les tarifs commencent à partir de 5000€.</li>
            <li><strong>Formations Personnalisées :</strong> Les tarifs peuvent varier en fonction de la durée et du contenu des formations. Ils peuvent aller de quelques centaines à quelques milliers d'euros.</li>
            <li><strong>Analyse de Données :</strong> Les tarifs dépendent de la quantité de données à analyser et de la complexité de l'analyse. Ils peuvent commencer à partir de 1000€.</li>
            <li><strong>Audit et Conseils :</strong> Les tarifs peuvent varier en fonction de l'étendue de l'audit et des recommandations fournies. Ils peuvent commencer à partir de 500€.</li>
          </ul>
        </div>
      )
      ,
    },
    {
      question: "Travaillez-vous avec des entreprises de toutes tailles ?",
      response: (
        <span>
          Oui, nous travaillons avec des entreprises de toutes tailles, des startups aux grandes entreprises, en adaptant nos services à vos besoins spécifiques.
        </span>
      ),
    },
    {
      question: "Comment puis-je vous contacter pour plus d'informations ou pour discuter de mon projet ?",
      response: (
        <span>
          N'hésitez pas à nous envoyer un mail directement en utilisant notre <Link to="/contact">formulaire de contact</Link> !
        </span>
      ),
    },
    {
      question: "Quels sont les modes de paiement acceptés ?",
      response: (
        <span>
          Nous acceptons les paiements par virement bancaire.
        </span>
      ),
    },
  ];

  return (
    <section id="FAQ" className="bgLight colorLight">
      <div className="container py-5">
        <h1 className="text-center title-decoration section-title py-4">FAQ</h1>
        <div className="row">
          {FAQItemData.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              response={item.response}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
