// src/pages/PageRealisations.tsx
import React from 'react';
import { LegalNotice } from '../components/LegalNotice';
import { Footer } from '../components/Footer';
const PageRealisations: React.FC = () => {
    return (
      <>
        <LegalNotice />
        <Footer backgroundColor="bgDark colorDark" />
      </>
    );
  };
  
  export default PageRealisations;