import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import './BlogItem.css';

interface BlogItemProps {
  title: string;
  texte: string;
  image: string;
  tags: string[];
  date: string;
  link: string;
  position: 'odd' | 'even';
}

const BlogItem: React.FC<BlogItemProps> = ({ title, texte, image, tags, date, link, position }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className={`blogItem ${position}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {position === 'even' && (
        <>
          <div className="blogItemImage m-auto rounded">
            <img src={image} className="img-fluid" alt="Blog illustration" />
          </div>
          <div className="blog-item-content">
            <h3 className="p-5 m-0"><span>{title}</span></h3>
            <p>{texte}</p>
            <Link to={link} className={`stretched-link p-4 ${isHovered ? 'hovered' : ''}`}>
              <div className="learn-more-button learn-more-button-white text-center py-2 px-4">
                <small>LIRE L'ARTICLE</small>
              </div>
            </Link>
          </div>
        </>
      )}
      {position === 'odd' && (
        <>
          <div className="blog-item-content">
            <h3 className="pb-5 pt-5 pt-md-0 m-0"><span>{title}</span></h3>
            <p>{texte}</p>
            <Link to={link} className={`stretched-link p-4 ${isHovered ? 'hovered' : ''}`}>
              <div className="learn-more-button learn-more-button-white text-center py-2 px-4">
                <small>LIRE L'ARTICLE</small>
              </div>
            </Link>
          </div>
          <div className="blogItemImage m-auto rounded">
            <img src={image} className="img-fluid" alt="Blog illustration" />
          </div>
        </>
      )}
    </div>
  );
};

export default BlogItem;
