// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { ScrollToTopButton } from './components/ScrollToTopButton';
import { ScrollToTop } from './components/ScrollToTop';
import Home from './pages/Home';
import PageFAQ from './pages/PageFAQ';
import PageRealisations from './pages/PageRealisations';
import PageContact from './pages/PageContact';
import PageBlog from './pages/PageBlog';
import PageArticleBlog from './pages/PageArticleBlog';
import PageConfidentialite from './pages/PageConfidentialite';
import PageCGU from './pages/PageCGU';
import PageMentionsLegales from './pages/PageMentionsLegales';


const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<PageFAQ />} />
          {/* <Route path="/realisations" element={<PageRealisations />} /> */}
          <Route path="/contact" element={<PageContact />} />
          <Route path="/blog" element={<PageBlog />} />
          <Route path="/articleBlog/:id" element={<PageArticleBlog />} />
          <Route path="/PolitiqueDeConfidentialite" element={<PageConfidentialite />} />
          <Route path="/CGU" element={<PageCGU />} />
          <Route path="/MentionsLegales" element={<PageMentionsLegales />} />
        </Routes>
        <ScrollToTopButton />
      </div>
    </Router>
  );
};

export default App;
