// ServiceCard.tsx
import React, { useState } from 'react';
import './ServiceCard.css';

interface ServiceCardProps {
  title: string;
  list?: string[]; // Liste optionnelle de chaînes
  numero: string;
  image: string;
  hoverImage: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, list, numero, image, hoverImage }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="col mb-4">
      <div className="card service-card h-100"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="card-header pt-5 px-4 position-relative">
          <h4 className="card-title py-3">{title}</h4>
          <img 
            src={isHovered ? hoverImage : image} 
            className="light-background" 
            alt=""
          />
        </div>
        <div className="card-body rounded px-4">
          {list && (
            <ul className="lead">
              {list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
//<span>{numero}</span>

export default ServiceCard;