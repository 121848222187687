// src/components/LegalNotice.tsx
import React from 'react';

const LegalNotice: React.FC = () => {
  return (
    <section id="LegalNotice" className="bgLight colorLight">
      <div className="container py-5">
            <h1 className="text-center title-decoration section-title py-4">Mentions Légales</h1>
            <div className="row">
                <div className="col-10 m-auto">
                    <section className="my-4">
                    <h2>Identité de l'Éditeur</h2>
                    <p>
                        <strong>Nom de l'entreprise :</strong> GoodShi<br />
                        <strong>Adresse du siège social :</strong> 20 Rue Simon Jallade, 69110 Sainte Foy Les Lyon, France<br />
                        <strong>Téléphone :</strong> Non communiqué<br />
                        <strong>Email : </strong><a href="mailto: goodshiteam@gmail.com">goodshiteam@gmail.com</a><br />
                        <strong>Numéro RCS :</strong> 925 158 867 R.C.S. Lyon<br />
                        <strong>Numéro de TVA intracommunautaire :</strong> FR93925158867
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Directeur de la Publication</h2>
                    <p>
                        <strong>Nom :</strong> Alfandari Julien
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Hébergeur du Site</h2>
                    <p>
                        <strong>Nom :</strong> OVH<br />
                        <strong>Adresse :</strong> 2 Rue Kellermann, 59100 Roubaix, France<br />
                        <strong>Téléphone :</strong> +33 9 72 10 10 07
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Propriété Intellectuelle</h2>
                    <p>
                        Le contenu de ce site est protégé par les lois en vigueur sur la propriété intellectuelle et le droit d'auteur. Tous les droits de reproduction sont réservés, y compris les documents téléchargeables et les représentations iconographiques et photographiques.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Conditions d'Utilisation</h2>
                    <p>
                        Pour plus d'informations sur les conditions d'utilisation de notre site, veuillez consulter nos <a href="/CGU">Conditions Générales d'Utilisation</a>.
                    </p>
                    </section>

                    <section className="my-4">
                    <h2>Protection des Données Personnelles</h2>
                    <p>
                        Pour en savoir plus sur la manière dont nous collectons et traitons vos données personnelles, veuillez consulter notre <a href="/PolitiqueDeConfidentialite">Politique de Confidentialité</a>.
                    </p>
                    </section>
                </div>
            </div>
        </div>
    </section>
  );
};

export default LegalNotice;
