// src/components/Footer.tsx
import React from 'react';
import FooterLinkSection from './FooterLinkSection';
import { Link } from 'react-router-dom';
import './Footer.css';

interface Link {
  href: string;
  label: string;
  external?: boolean; // Ajout d'une propriété pour indiquer les liens externes
}

interface LinkSection {
  title: string;
  links: Link[];
}

interface FooterProps {
  backgroundColor: string;
}

const linkSections: LinkSection[] = [
  {
    title: "A propos",
    links: [
      /*{ href: "/realisations", label: "Nos réalisations" },*/
      { href: "/blog", label: "Notre blog" },
      { href: "/contact", label: "Nous contacter" },
      { href: "/FAQ", label: "FAQ" },
    ],
  },
  {
    title: "Communauté",
    links: [
      /*{ href: "https://twitch.tv/yourchannel", label: "Twitch", external: true },*/
      { href: "/blog", label: "Blog" },
      { href: "https://discord.com/invite/yourinvite", label: "Discord", external: true },
    ],
  }/*,
  {
    title: "Socials",
    links: [
      { href: "https://instagram.com/yourprofile", label: "Instagram", external: true },
      { href: "https://twitter.com/yourprofile", label: "Twitter", external: true },
      { href: "https://facebook.com/yourpage", label: "Facebook", external: true },
    ],
  },*/
];

const Footer: React.FC<FooterProps> = ({ backgroundColor }) => {
  return (
    <footer className={`py-4 ${backgroundColor}`}>
      <div className="container-fluid">
        <div className="row footer mx-3 mb-3 text-center">
          <div className="col-6 col-lg-3 col-xl-1 m-auto">
            <Link to="/">
              <img src="/images/GoodShi.png" alt="Logo" className="w-100" />
            </Link>
          </div>
          <div className="col-12 col-lg-6 col-xl-8"></div>
          <div className="col-12 col-lg-3 col-xl-3">
            <div className="row">
              {linkSections.map((section, index) => (
                <FooterLinkSection
                  key={index}
                  title={section.title}
                  links={section.links}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="row text-center px-3">
          <div className="col-md-4 text-md-start">
            <p>&copy;2024 GoodShi. Tous droits réservés</p>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-2 text-md-end">
            <p>
              <Link to="/MentionsLegales" className="text-decoration-none">
                Mentions légales
              </Link>
            </p>
          </div>
          <div className="col-md-2 text-md-end">
            <p>
              <Link to="/PolitiqueDeConfidentialite" className="text-decoration-none">
                Politique de confidentialité
              </Link>
            </p>
          </div>
          <div className="col-md-2 text-md-end">
            <p>
              <Link to="/CGU" className="text-decoration-none">
                Conditions d'utilisation
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
