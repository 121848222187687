import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import "./ArticleBlog.css";
const BlogItemData = [
  {
    title: "Introduction à React",
    texteIntro:
      "React est une bibliothèque JavaScript développée par Facebook pour créer des interfaces utilisateur interactives et dynamiques. Il permet de construire des applications web en utilisant des composants réutilisables. Dans cet article, nous allons explorer les bases de React pour vous aider à démarrer.",
    texteComplet: `<h2 class="pb-4"><span class="pe-4">Pourquoi utiliser React ?</span></h2>
            <p>React offre plusieurs avantages qui le rendent populaire parmi les développeurs :</p>
            <ul>
            <li><strong>Composants réutilisables</strong> : React permet de créer des composants modulaires qui peuvent être réutilisés dans différentes parties de l'application.</li>
            <li><strong>Mise à jour efficace</strong> : React utilise un <em>Virtual DOM</em> pour optimiser les mises à jour de l'interface utilisateur, rendant les applications plus rapides.</li>
            <li><strong>Écosystème riche</strong> : Avec une grande communauté et de nombreuses bibliothèques tierces, React offre une multitude d'outils et de ressources pour les développeurs.</li>
            </ul>

            <h2 class="pb-4"><span class="pe-4">Installation de React</span></h2>
            <p>Pour commencer à utiliser React, vous pouvez utiliser <code>create-react-app</code>, un outil de configuration qui vous permet de créer rapidement une nouvelle application React avec une configuration par défaut.</p>
            <pre><code>npx create-react-app my-app
            cd my-app
            npm start
            </code></pre>
            <p>Cette commande crée un nouveau projet React dans un dossier nommé <code>my-app</code> et démarre le serveur de développement.</p>

            <h2 class="pb-4"><span class="pe-4">Concepts de base de React</span></h2>
            <p>Voici quelques concepts de base que vous devez connaître pour commencer avec React :</p>

            <h3>JSX</h3>
            <p>JSX est une syntaxe qui permet d'écrire du HTML dans du JavaScript. Il est compilé en appels JavaScript par Babel.</p>
            <pre><code>const element = &lt;h1&gt;Hello, world!&lt;/h1&gt;;</code></pre>

            <h3>Composants</h3>
            <p>Les composants sont les blocs de construction de base d'une application React. Ils peuvent être des classes ou des fonctions.</p>

            <h4>Composant de classe</h4>
            <pre><code>class Welcome extends React.Component {
            render() {
                return &lt;h1&gt;Hello, {this.props.name}&lt;/h1&gt;;
            }
            }</code></pre>

            <h4>Composant fonctionnel</h4>
            <pre><code>function Welcome(props) {
            return &lt;h1&gt;Hello, {props.name}&lt;/h1&gt;;
            }</code></pre>

            <h3>Props et State</h3>
            <p>Les <strong>props</strong> (propriétés) sont des arguments passés aux composants, tandis que le <strong>state</strong> (état) est une donnée privée du composant qui peut changer au fil du temps.</p>

            <h2 class="pb-4"><span class="pe-4">Créer votre premier composant React</span></h2>
            <p>Voici un exemple de création d'un simple composant React qui affiche "Hello, World!" :</p>
            <pre><code>import React from 'react';

            function HelloWorld() {
            return &lt;h1&gt;Hello, World!&lt;/h1&gt;;
            }

            export default HelloWorld;</code></pre>
            <p>Ensuite, vous pouvez utiliser ce composant dans votre application principale :</p>
            <pre><code>import React from 'react';
            import ReactDOM from 'react-dom';
            import HelloWorld from './HelloWorld';

            ReactDOM.render(&lt;HelloWorld /&gt;, document.getElementById('root'));</code></pre>

            <h2 class="pb-4"><span class="pe-4">Conclusion</span></h2>
            <p>React est une bibliothèque puissante et flexible qui facilite la création d'interfaces utilisateur dynamiques. En comprenant les bases, comme les composants, les props, et le state, vous serez bien préparé pour explorer des concepts plus avancés et construire des applications web sophistiquées. Bonne chance avec vos projets React !</p>
            `,
    image: "/images/HollowKnight.jpg",
    tags: ["react", "débutant"],
    date: "6 juin 2024",
    id: "introduction-react",
  },
  {
    title: "L'importance des sites internet en 2024",
    texteIntro:
      "Dans le monde numérique d'aujourd'hui, les sites internet jouent un rôle crucial pour les entreprises, les particuliers, et les organisations. En 2024, leur importance ne fait que croître avec les avancées technologiques et les attentes croissantes des utilisateurs. Cet article explore pourquoi les sites internet sont indispensables en 2024 et comment ils peuvent bénéficier à différentes entités.",
    texteComplet: `<h2 class="pb-4"><span class="pe-4">Pourquoi un site internet est essentiel en 2024</span></h2>
            <p>Les sites internet sont devenus un outil incontournable pour atteindre une audience mondiale, offrir des services, et établir une présence en ligne crédible. Voici quelques raisons clés pour lesquelles un site internet est indispensable en 2024 :</p>
    
            <h2 class="pb-4"><span class="pe-4">Visibilité et Accessibilité</span></h2>
            <p>Un site internet permet à votre entreprise ou organisation d'être accessible 24/7, ce qui est crucial dans un monde où les utilisateurs s'attendent à pouvoir trouver des informations et effectuer des transactions à tout moment.</p>
            <ul>
            <li><strong>Portée mondiale</strong> : Un site internet vous permet de toucher une audience globale, dépassant les limitations géographiques.</li>
            <li><strong>Disponibilité continue</strong> : Contrairement à un magasin physique, un site internet est toujours ouvert, offrant une accessibilité continue à vos clients.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">Crédibilité et Professionnalisme</span></h2>
            <p>Avoir un site internet bien conçu renforce la crédibilité de votre marque et montre que vous êtes sérieux dans votre domaine. Voici quelques éléments qui contribuent à cette crédibilité :</p>
            <ul>
            <li><strong>Présentation professionnelle</strong> : Un design moderne et professionnel instille confiance chez les visiteurs.</li>
            <li><strong>Témoignages et avis</strong> : Afficher des témoignages de clients et des avis positifs renforce la confiance et la crédibilité.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">Marketing et Engagement</span></h2>
            <p>Un site internet est une plateforme idéale pour déployer vos stratégies de marketing et engager votre audience. Voici comment :</p>
    
            <h3>SEO et Marketing de Contenu</h3>
            <p>Le référencement naturel (SEO) est crucial pour attirer du trafic organique sur votre site. En 2024, le contenu de qualité est toujours roi.</p>

            <h3>Réseaux Sociaux et Intégration</h3>
            <p>Intégrer vos réseaux sociaux à votre site internet permet de maximiser votre portée et d'engager votre audience de manière plus efficace.</p>
            <ul>
            <li><strong>Partage facile</strong> : Ajoutez des boutons de partage sur les réseaux sociaux pour permettre à vos visiteurs de partager votre contenu.</li>
            <li><strong>Flux en direct</strong> : Intégrez des flux en direct de vos comptes de réseaux sociaux pour garder votre site à jour avec vos dernières activités.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">E-commerce et Ventes en Ligne</span></h2>
            <p>Pour les entreprises, un site internet peut servir de plateforme de vente en ligne, offrant une boutique virtuelle ouverte 24/7.</p>
    
            <h3>Facilité de Gestion</h3>
            <p>Des plateformes comme Shopify, WooCommerce, et autres rendent la gestion de votre boutique en ligne plus facile que jamais.</p>

            <h3>Options de Paiement Variées</h3>
            <p>Offrir plusieurs options de paiement, telles que les cartes de crédit, PayPal, et même les cryptomonnaies, peut améliorer l'expérience client et augmenter vos ventes.</p>
    
            <h2 class="pb-4"><span class="pe-4">Conclusion</span></h2>
            <p>En 2024, un site internet est plus qu'une simple présence en ligne ; c'est un outil stratégique qui peut transformer votre entreprise ou organisation. De la visibilité mondiale à l'engagement client et à l'e-commerce, les avantages sont nombreux et variés. Assurez-vous que votre site est à jour, bien conçu, et optimisé pour les moteurs de recherche pour tirer le meilleur parti de votre présence en ligne.</p>
            `,
        image: "/images/illustration-site-web.jpg",
        tags: ["site internet", "2024"],
        date: "5 juin 2024",
        id: "importance-sites-internet-2024"
    },
    {
        title: "Introduction aux Langages de Programmation",
        texteIntro: "Dans le monde du développement informatique, les langages de programmation jouent un rôle fondamental. En 2024, comprendre les bases des langages de programmation est essentiel pour quiconque souhaite se lancer dans le développement ou améliorer ses compétences. Cet article explore les principaux langages de programmation, leurs utilisations et pourquoi ils sont indispensables.",
        texteComplet: `<h2 class="pb-4"><span class="pe-4">Pourquoi apprendre un langage de programmation en 2024</span></h2>
            <p>Les langages de programmation sont les outils de base pour créer des logiciels, des sites web, des applications mobiles, et bien plus encore. Voici quelques raisons pour lesquelles apprendre un langage de programmation est crucial en 2024 :</p>
    
            <h2 class="pb-4"><span class="pe-4">Compétences et Opportunités</span></h2>
            <p>Apprendre un langage de programmation peut ouvrir de nombreuses portes dans le monde professionnel et offrir des opportunités variées. Voici quelques avantages clés :</p>
            <ul>
            <li><strong>Développement de carrière</strong> : Les compétences en programmation sont très recherchées et peuvent mener à des carrières lucratives dans divers domaines.</li>
            <li><strong>Résolution de problèmes</strong> : La programmation développe des compétences analytiques et la capacité à résoudre des problèmes complexes.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">Les principaux langages de programmation</span></h2>
            <p>Il existe de nombreux langages de programmation, chacun ayant ses propres forces et applications. Voici quelques-uns des langages les plus populaires en 2024 :</p>
            <ul>
            <li><strong>Python</strong> : Connu pour sa syntaxe simple et sa polyvalence, Python est idéal pour les débutants et est utilisé dans des domaines tels que le développement web, l'analyse de données, et l'intelligence artificielle.</li>
            <li><strong>JavaScript</strong> : Essentiel pour le développement web, JavaScript permet de créer des interfaces utilisateur interactives et est souvent utilisé avec des frameworks comme React et Vue.js.</li>
            <li><strong>Java</strong> : Utilisé dans le développement d'applications Android et les systèmes d'entreprise, Java est un langage robuste et orienté objet.</li>
            <li><strong>C++</strong> : Utilisé pour le développement de logiciels système, de jeux vidéo, et d'applications nécessitant des performances élevées, C++ est un langage puissant mais complexe.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">Éducation et Ressources</span></h2>
            <p>Il existe de nombreuses ressources pour apprendre à programmer, que vous soyez débutant ou que vous cherchiez à vous perfectionner. Voici quelques suggestions :</p>
    
            <h3>Plateformes en ligne</h3>
            <p>Des plateformes comme Codecademy, Coursera, et Udemy offrent des cours pour tous les niveaux et dans de nombreux langages de programmation.</p>
    
            <h3>Communautés et Forums</h3>
            <p>Participer à des communautés en ligne comme Stack Overflow, Reddit, et GitHub peut vous aider à apprendre des autres, poser des questions, et collaborer sur des projets.</p>
            <ul>
            <li><strong>Apprentissage collaboratif</strong> : Travailler avec d'autres programmeurs peut accélérer votre apprentissage et vous exposer à différentes approches et solutions.</li>
            <li><strong>Projets pratiques</strong> : Contribuer à des projets open-source est une excellente façon de gagner de l'expérience pratique.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">Perspectives et Futur</span></h2>
            <p>Les langages de programmation évoluent constamment pour s'adapter aux nouvelles technologies et aux besoins du marché. En 2024, rester à jour avec les tendances et les nouveautés est crucial pour rester compétitif.</p>
    
            <h3>Intelligence Artificielle et Machine Learning</h3>
            <p>Des langages comme Python et R sont de plus en plus utilisés pour le développement d'applications d'intelligence artificielle et de machine learning, des domaines en pleine expansion.</p>
    
            <h3>Développement Mobile et Web</h3>
            <p>Les frameworks comme Flutter (utilisant Dart) et React Native (utilisant JavaScript) sont populaires pour le développement d'applications mobiles multiplateformes.</p>
            <ul>
            <li><strong>Écosystème en croissance</strong> : Les communautés de développement mobile et web continuent de croître, offrant de nombreuses opportunités de collaboration et d'innovation.</li>
            <li><strong>Adoption de nouvelles technologies</strong> : Restez à l'affût des nouveaux langages et frameworks qui peuvent offrir des avantages spécifiques pour certains types de projets.</li>
            </ul>
    
            <h2 class="pb-4"><span class="pe-4">Conclusion</span></h2>
            <p>Apprendre un langage de programmation en 2024 est une compétence précieuse qui peut transformer votre carrière et vos opportunités. Que vous soyez débutant ou que vous cherchiez à approfondir vos connaissances, il existe une multitude de ressources et de communautés pour vous aider. Choisissez le langage qui correspond le mieux à vos objectifs et commencez à explorer le monde fascinant de la programmation.</p>
            `,
        image: "/images/illustration-programming.jpg",
        tags: ["langages de programmation", "debutant"],
        date: "5 juillet 2024",
        id: "introduction-langages-programmation-2024"
    }
    
];
const FAQ: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const article = BlogItemData.find((item) => item.id === id);
  if (!article) {
    return <div>Article not found</div>;
  }
  const generateTOC = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const headers = doc.querySelectorAll("h2");
    const toc = Array.from(headers).map((header, index) => {
      const id = `section-${index}`;
      header.id = id;
      return (
        <li key={id}>
          <a href={`#${id}`}>{header.textContent}</a>
        </li>
      );
    });
    article.texteComplet = doc.body.innerHTML; // update texteComplet with IDs
    return toc;
  };

  const tocItems = generateTOC(article.texteComplet);

  return (
    <section id="ArticleBlog" className="bgDark">
      <div className="container py-5">
        <div className="row bgLight colorLight rounded my-5 py-3">
          <p className="text-center text-muted">
            {article.tags.map((tag, index) => (
              <small key={index} className="text-muted me-2">
                <FontAwesomeIcon icon={faTag} /> {tag}
              </small>
            ))}
          </p>
          <h1 className="text-center section-title fs-1">{article.title}</h1>
          <p className="text-center text-muted">Publié le : {article.date}</p>
          <p className="text-center">{article.texteIntro}</p>
          <img
            src={article.image}
            alt="Blog illustration"
            className="img-fluid rounded"
          />
          <div className="row">
            <div className="col-12 col-lg-4 order-1 sticky-toc-container">
              <div className="sticky-toc">
                <h2 className="mt-5">Sommaire</h2>
                <ul>{tocItems}</ul>
              </div>
            </div>
            <div className="col-12 col-lg-8 order-2">
              <div
                className="mt-5 texteArticleBlog"
                dangerouslySetInnerHTML={{ __html: article.texteComplet }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
