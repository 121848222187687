import React from 'react';
import './FeatureItem.css';

interface FeatureItemProps {
    title: string;
    description: string;
    link: string;
    image: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ title, description, link, image }) => {
  return (
    <div className="col-md-6 mx-auto mb-5">
      <div className="card mb-4 feature-card h-100">
        <div className="card-body position-relative text-center px-5 pt-5">
          <h4 className="card-title"><span>{title}</span></h4>
          <p className="lead pre-line">{description}</p>
          <img src={image} className="feature-image" alt=""/>
        </div>
        <div className="card-footer text-center border-0">
          <a href={link} className="card-link feature-link text-decoration-none">En savoir plus</a>
        </div>
      </div>
    </div>
  );
};

export default FeatureItem;
