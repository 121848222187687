import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faPhoneFlip, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';

import './Contact.css';

const Contact: React.FC = () => {
  const [isEmailFailed, setIsEmailFailed] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isButtonMailClicked, setIsButtonMailClicked] = useState(false);
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    nom: '',
    prenom: '',
    email: '',
    message: ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Reset error state for the field being changed
  };

  const submitForm = () => {
    const button = document.getElementById("buttonSubmit") as HTMLButtonElement;
    if (button) {
      button.click();
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      const newErrors = {
        nom: formData.nom.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/) ? '' : 'Nom invalide.\nUtilisez uniquement des lettres et des caractères spéciaux autorisés.',
        prenom: formData.prenom.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/) ? '' : 'Prenom invalide.\nUtilisez uniquement des lettres et des caractères spéciaux autorisés.',
        email: formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) ? '' : 'Email invalide.',
        message: formData.message ? '' : 'Message requis.'
      };
      setErrors(newErrors);
      return;
    }
    setIsButtonMailClicked(true);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        setIsEmailSent(true);
        console.log('L\'email a été envoyé avec succès');
      } else {
        console.log(result.errors || result.message);
        setIsEmailFailed(true);
        if (result.errors) {
          setErrors(result.errors);
        }
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
      setIsEmailFailed(true);
    }
    
  };
  
  let buttonText: React.ReactNode;
  if (isEmailFailed){
    buttonText = "Erreur lors de l'envoie de mail";
  }
  else if (isButtonMailClicked && !isEmailSent) {
    buttonText = <img src={`images/loading.gif`} alt="Chargement..." style={{ width: '50px', height: '50px' }} />;
  } else if (!isButtonMailClicked && !isEmailSent) {
    buttonText = 'Envoyer';
  } else if (isButtonMailClicked && isEmailSent) {
    buttonText = (
      <>
        Envoyé.
        <br />
        Merci pour votre message, nous vous répondrons rapidement.
      </>
    );
  }
  

  return (
    <section id="Contact" className="contact-container pt-5 pb-md-5 bgLight colorLight">
      <Container fluid>
        <h1 className="text-center title-decoration section-title py-4">Nous contacter</h1>
        <Row className='mt-5'>
          <Col md="2"></Col>
          <Col md="3" className='pb-3'>
            <p>Vous pouvez nous contacter si vous avez des questions ou si vous souhaitez travailler avec nous.</p>
            <div className="contact-form">
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formNom">
                  {errors.nom && <div className="text-danger mb-2 pre-line"> {errors.nom} </div>}
                  <Form.Control
                    placeholder="Nom"
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    required
                    disabled={isButtonMailClicked}
                    pattern="^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$"
                    isInvalid={!!errors.nom}
                  />
                </Form.Group>
                <Form.Group controlId="formPrenom">
                  {errors.prenom && <div className="text-danger mb-2 pre-line">{errors.prenom}</div>}
                  <Form.Control
                    placeholder="Prénom"
                    type="text"
                    name="prenom"
                    value={formData.prenom}
                    disabled={isButtonMailClicked}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.prenom}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  {errors.email && <div className="text-danger mb-2">{errors.email}</div>}
                  <Form.Control
                    placeholder="Adresse mail"
                    type="email"
                    name="email"
                    value={formData.email}
                    disabled={isButtonMailClicked}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.email}
                  />
                </Form.Group>
                <Form.Group controlId="formMessage">
                  {errors.message && <div className="text-danger mb-2">{errors.message}</div>}
                  <Form.Control
                    placeholder="Message"
                    as="textarea"
                    rows={5}
                    name="message"
                    value={formData.message}
                    disabled={isButtonMailClicked}
                    onChange={handleChange}
                    required
                    isInvalid={!!errors.message}
                  />
                </Form.Group>
                <button type="submit" className='d-none' id='buttonSubmit' disabled={isButtonMailClicked} />
                <div className='text-center'>
                  <div className={`validationContactButton${isButtonMailClicked ? 'Disabled' : ''}`} onClick={submitForm}>
                    {buttonText}
                  </div>
                </div>
              </Form>
            </div>
          </Col>
          <Col md="2"></Col>
          <Col md="5" className='p-0'>
            <div className="info-section colorLightEncapsuled p-4 mt-md-5">
              <div className='orange-box d-none d-md-block'></div>
              <h4 className='p-2'>Nos informations</h4>
              <p className='lead'><FontAwesomeIcon icon={faAt} className='me-3 solid ps-5' /> goodshiteam@gmail.com</p>
              {/*<p className='lead'><FontAwesomeIcon icon={faPhoneFlip} className='me-3 ps-5' /> TODO</p>*/}
              <p className='lead'><FontAwesomeIcon icon={faLocationDot} className='me-3 ps-5' /> 20 rue Simon Jallade 69110 Sainte Foy Les Lyon </p>
              <p className='lead'><FontAwesomeIcon icon={faClock} className='me-3 solid ps-5' /> Du lundi au vendredi de 9h à 17h</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
